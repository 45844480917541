body.modal-open {
    overflow-y: hidden;
}

.modal {
    position: fixed;
    z-index: 1001;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    /* background-color: blue; */
}

.modal-outer-wrap {
    /* padding-top: 20px;
    padding-bottom: 20px; */
}

.modal-content {
    position: relative;
    z-index: 10002;
    width: 97%;
    max-width: 400px;
    min-height: calc(100vh - 20px);
    margin: 10px auto;
    padding: 10px 0;
    box-shadow: 1px 1px 4px 4px rgba(0,0,0,.4);
    background-color: #f0f0f0;
}

.location-option-wrap.selected button {
    background-color: #DB927C !important;
    color: white !important;
}

.location-option-wrap button {
    margin: 0;
    box-shadow: none;
}

/* .digitizer {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1000;
    background: rgba(247, 221, 213, 0.6);

    padding: 0;
    margin: 0;
 
   box-shadow: none;
} */

.edit-response-email-wrap {
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(220, 220, 220);
}
.response-email-input {
    font-weight: bold;
    padding: 8px 8px;
}
.close-modal-button-wrap {
    margin-left: 0;
    max-width: 180px !important;
    background-color: #e7e7e7 !important;
}
.close-modal-button-wrap span {
    margin-left: 10px
}
.close-modal-button-wrap button {
    background-color: unset !important;
}

.close-modal-button-wrap button:hover {
    text-decoration: underline;
}


.modal-content-wrap {
    /* height: 100%;
    overflow-y: auto;
    position: relative;
    z-index: 10005; */
}

.modal-outer-wrap {
    position: fixed;
    z-index: 1001;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;

}

.modal-content-wrap {
        /* height: 100vh; */
    /* overflow-y: auto; */
    position: relative;
    z-index: 10005;
    overflow: hidden;
}

.modal-content {
    position: relative;
    z-index: 10002;
    width: 97%;
    max-width: 400px;
    min-height: calc(100vh - 20px);
    margin: 10px auto;
    padding: 10px 0;
    box-shadow: 1px 1px 4px 4px rgba(0,0,0,.4);
    background-color: #f0f0f0;
}
.digitizer {
    position: absolute;
    z-index: 1000;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;
    /* background-color: orange; */
    z-index: 1000;
    background: rgba(247, 221, 213, 0.6);
    padding: 0;
    margin: 0;
    box-shadow: none;
}
