* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border-radius: 0;
    border-width: 0;
    box-shadow: none;
    font-family: 'Carlito', sans-serif;
    font-size: 18px;
}
*:focus {
    border-width:  0;
}

body {
    font-size: 18px;
    background-color: #DCDCDC;
}

.formik-form {
    padding-bottom: 50px;
}

input, textarea, .open-select-modal {
    display: block;
    width: 100%;
    background-color: white;
    margin: 3px;
    padding: 5px;
    resize: none;
    padding: 6px .5rem;
}

input, textarea, button {
    box-shadow: 1px 1px 1px 1px rgba(0,0,0,.3);
}

.outer-wrap {
    margin: auto;
    max-width: 600px;
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;

}
.info-block {
    padding: 5px;
    border: 1px solid rgb(200, 200, 200);
    margin: 10px auto;
}
.info-block-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* border-bottom: 1px solid #DB927C; */
    margin-bottom: 5px;
}



.center-flex {
    display: flex;
    justify-content: center;
    align-items: center;
}

button {
    cursor: pointer;
}
.info-block button {
    
    margin: 3px;
    padding: 5px;
}
button.clear-section, 
button.block-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}
button.clear-section {
    padding: 7px;
    border-radius: 50%;
}
button.block-toggle {
    border-radius: 10%;
}
button.delete-item {
    padding: 18px 12px;
}
button.item-counter {
    display: block;
    margin: 5px auto;
    font-weight: bold;
    background-color: white;
}
button.submit-form {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background-color: #934730;
    margin-left: auto;
    box-shadow: 1px 1px 1px 1px rgba(0,0,0,.3);
    color: white;

}
button.submit-form span {
    margin-right: 8px;
}
button.submit-form:hover, button.item-counter:hover {
    text-decoration: underline;
}

.submit-button-wrap {
    margin-top: 30px;
}



.info-block h3 {
    flex: 1;
    padding-left: 15px;
    font-family: 'Bitter', serif;
    font-size: 1.2rem;
}

.input-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
}
.input-group p {
    width: 100%;
    text-align: left;
}
.input-group input {
    width: 100%;
}
textarea.description {
    min-height: 7em;
}
.item-wrap {
    list-style-type: none;
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 15px;
}
.item-wrap, .open-select-modal {
    margin-bottom: 15px;
}


.delete-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15px;
    height: 30px;
}
.item-textarea {
    padding: .5rem;
    margin: 5px;
}

.open-select-modal {
    width: 100%;
    font-size: 18px;
    /* font-weight: bold; */
}

.info-block-content {
    transition: all .7s ease-out;
    overflow: hidden;
}

.info-block.hide .info-block-content {
    max-height: 0px !important;    
}
.info-block-inner-content {
    padding: 5px;
    transition: opacity .7s ease-out;
    opacity: 1;
}
.info-block.hide .info-block-content .info-block-inner-content {
    opacity: 0;
}

.info-block .button.block-toggle .toggle-icon {
    transition: transform .7s ease-out;
    transform: rotate(0deg) !important;
}

.info-block.hide  button.block-toggle .toggle-icon {
    transform: rotate(180deg) !important;
}

.location-option-wrap {
    display: flex;
    flex-flow: row nowrap;
    margin: 8px auto;
    width: 100%;
    max-width: 350px;
    background: #fff !important;
    box-shadow: 1px 1px 3px 0px rgba(0,0,0,.4);
}

.location-option-text {
    font-size: 18px;
    display: block;
    flex: 1;
    padding: 5px 7px;
    background: #fff !important;
}

.location-option-indicator {
    width: 30px;
    background: #fff !important;
}
.location-option-wrap.selected .location-option-text {
    font-style: bold;
}
.location-option-wrap.selected .location-option-indicator {
    background-color: green;
}
